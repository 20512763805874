var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial-container",staticStyle:{"padding-top":"50px"}},[_c('BackHomeButton'),_c('hr',{staticClass:"divider"}),_vm._v(" "),_c('UserInfo'),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("基础教程")]),_c('iframe',{attrs:{"src":"./vod_vvip_foundation.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("工作流搭建基础")]),_c('iframe',{attrs:{"src":"./vod_building_workflow.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("工作流更新")]),_c('iframe',{attrs:{"src":"./vod_update_workflow.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("原图到工作流")]),_c('iframe',{attrs:{"src":"./vod_image_workflow.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("换衣工作流")]),_c('iframe',{attrs:{"src":"./vod_change_clothes.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("可灵教程")]),_c('iframe',{attrs:{"src":"./vod_klingai.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("剪映基础用法")]),_c('iframe',{attrs:{"src":"./vod_jianying1.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("养号方法")]),_c('iframe',{attrs:{"src":"./vod_owned_account.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("常见问题1")]),_c('iframe',{attrs:{"src":"./vod_tocn.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tutorial"},[_c('h2',[_vm._v("常见问题2")]),_c('iframe',{attrs:{"src":"./vod_toold.html","width":"720","height":"480","frameborder":"0","allowfullscreen":""}})])
}]

export { render, staticRenderFns }