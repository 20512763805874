<template>
    <div class="tutorial-container" style="padding-top: 50px;">
      <BackHomeButton /> 
      <hr class="divider" /> <!-- 添加分割线 -->
      <UserInfo /> 
      
      <div class="tutorial">
        <h2>基础教程</h2>
        <iframe src="./vod_vvip_foundation.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>工作流搭建基础</h2>
        <iframe src="./vod_building_workflow.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>工作流更新</h2>
        <iframe src="./vod_update_workflow.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>原图到工作流</h2>
        <iframe src="./vod_image_workflow.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>换衣工作流</h2>
        <iframe src="./vod_change_clothes.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>可灵教程</h2>
        <iframe src="./vod_klingai.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>剪映基础用法</h2>
        <iframe src="./vod_jianying1.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>养号方法</h2>
        <iframe src="./vod_owned_account.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>常见问题1</h2>
        <iframe src="./vod_tocn.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
      <div class="tutorial">
        <h2>常见问题2</h2>
        <iframe src="./vod_toold.html" width="720" height="480" frameborder="0" allowfullscreen></iframe>
      </div>
    </div>
  </template>
  
  <script>
  import BackHomeButton from '@/components/BackHomeButton.vue'; // 导入组件
  import UserInfo from '@/components/UserInfo.vue'; // 导入组件

  export default {
    name: 'MoyingTutorial',
    components: {
      BackHomeButton, // 注册组件
      UserInfo, // 注册组件
    },
    methods: {
      checkAuth() {
        try {
            const token = localStorage.getItem('access_token');
            if (!token) {
            this.$router.push('/login');
            } else {
            this.isLoggedIn = true;
            }
        } catch (error) {
            console.error('检查登录状态时发生错误:', error);
            this.isLoggedIn = false;
        }
      }
    },
    mounted() {
      this.checkAuth();
    }
  };
  </script>
  
  <style scoped>
  .tutorial-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px; /* Creates even spacing between grid items */
    justify-content: center;
    align-items: start;
    max-width: 1440px; /* Optional: limit total width */
    margin: 0 auto; /* Center the grid */
    padding-top: 50px; /* 增加顶部间距 */
  }
  
  .tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .tutorial iframe {
    width: 720px;
    height: 480px;
    max-width: 100%;
    object-fit: contain;
  }
  
  .tutorial h2 {
    margin-bottom: 10px;
  }
  
  .tutorial p {
    margin-top: 0;
  }

  /* 新增样式以调整按钮和用户信息组件的布局 */
  BackHomeButton, UserInfo {
    grid-column: span 2; /* 使按钮和用户信息组件占据整行 */
    margin-bottom: 20px; /* 添加底部间距 */
    text-align: center; /* 居中对齐 */
  }

  .divider {
    grid-column: span 2; /* 使分割线占据整行 */
    margin: 20px 0; /* 添加上下间距 */
    border: 0.1px solid #ccc; /* 设置分割线样式为1px */
  }
  </style>