<template>
  <div class="ai-tool-home">
    <header>
      <div class="header-content">
        <h1>墨影AI工具箱</h1>
      </div>
      <div class="auth-buttons">
        <UserInfo v-if="isLoggedIn" />
        <router-link v-else to="/login" class="login-link">登录</router-link>
      </div>
    </header>

    <section class="tool-grid">
      <router-link 
        v-for="tool in tools" 
        :key="tool.name" 
        :to="tool.route" 
        class="tool-card"
      >
        <img :src="tool.icon" :alt="tool.name">
        <h3>{{ tool.name }}</h3>
        <p>{{ tool.description }}</p>
      </router-link>
    </section>
  </div>
</template>

<script>
import UserInfo from '@/components/UserInfo.vue';

export default {
  name: 'AiToolHome',
  components: {
    UserInfo
  },
  mounted() {
    document.title = '墨影AI - 首页'
  },
  data() {
    return {
      isLoggedIn: false,
      tools: [
        { name: 'AI图像反推', description: 'JoyCaption反推提示词', icon: '/icons/caption.jpg', route: '/image-caption' },
        { name: 'Lora模型训练', description: 'Lora模型训练(Flux)', icon: '/icons/model_training.jpg', route: '/model-training' },
        { name: '图片批量打标', description: 'JoyCaption模型批量标注', icon: '/icons/batch_labeling.jpg', route: '/batch-labeling' },
        { name: 'ComfyUI一键跳舞', description: '网红美女跳舞专用', icon: '/icons/comfyui_v2.jpg', route: '/comfyui_v2' },
        { name: 'ComfyUI一键网红美女', description: '教学,模型使用,变现', icon: '/icons/comfyui.png', route: '/comfyui' },
        // { name: 'AI图像增强', description: 'AI图像增强', icon: '/icons/clarity-upscaler.png', route: '/model-training' },
        // { name: 'AI去水印', description: 'AI去水印', icon: '/icons/watermark.jpg', route: '/model-training' },
        { name: '作品展示', description: 'AI颜值变现,学员作品展示', icon: '/icons/team.jpg', route: '/aigc717' },
        // { name: '使用教程', description: '墨影AI使用教程', icon: '/icons/help.jpg', route: '/help' },
      ]
    }
  },
  created() {
    this.checkLoginStatus()
  },
  methods: {
    async checkLoginStatus() {
      try {
        const apiUrl = process.env.VUE_APP_API_URL || ''
        const token = localStorage.getItem('access_token');
        if (!token) {
          this.isLoggedIn = false
        } else {
          this.isLoggedIn = true;
          // 获取用户信息
          const userResponse = await fetch(`${apiUrl}/api/user_info`, {
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('access_token')}`
              }
            })
            if (userResponse.ok) {
              const userData = await userResponse.json()
              this.username = userData.username
            } else {
              this.isLoggedIn = false
              localStorage.removeItem('access_token');
              console.error('获取用户信息失败')
            }
        }
      } catch (error) {
        console.error('检查登录状态时发生错误:', error)
        localStorage.removeItem('access_token');
        this.isLoggedIn = false
      }
    }
  }
}
</script>

<style scoped>
.ai-tool-home {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  color: #e0e0e0;
}

header {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  position: relative;
}

.header-content {
  text-align: center;
  width: 100%;
}

header h1 {
  color: #ffffff;
  margin: 0;
}

.auth-buttons {
  position: absolute;
  top: 0;
  right: 0;
}

.logout-button, .login-link {
  background-color: #4caf50;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  text-decoration: none;
  font-size: 1rem;
}

.logout-button:hover, .login-link:hover {
  background-color: #45a049;
}

.tool-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
}

.tool-card {
  background-color: #2a2a2a;
  border-radius: 8px;
  padding: 1.5rem;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: calc(50% - 1rem);
  max-width: 300px;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.tool-card::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: conic-gradient(
    transparent,
    transparent,
    transparent,
    #ff00bd,
    #00ffff,
    #00ff00,
    #ffff00,
    #ff0000,
    transparent
  );
  animation: rotate 4s linear infinite;
  z-index: -1;
}

.tool-card::after {
  content: '';
  position: absolute;
  top: 4px;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: #1a1a1a;
  border-radius: 6px;
  z-index: -1;
}

.tool-card:hover::before {
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.tool-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.tool-card h3 {
  color: #ffffff;
  margin-top: 1rem;
}

.tool-card p {
  color: #b0b0b0;
}

@media (max-width: 768px) {
  .tool-card {
    width: 100%;
  }
  
  header {
    flex-direction: column;
  }
  
  .auth-buttons {
    position: static;
    margin-top: 1rem;
  }
}
</style>
