<template>
  <div class="buy-points">
    <BackHomeButton />
    <h1 class="title">购买积分</h1>
    <p class="subtitle">充值积分永久有效，积分充值不退不换！</p>
    
    <div class="points-packages">
      <div 
        v-for="pointPackage in packages" 
        :key="pointPackage.id" 
        class="package-card"
        :class="{ 'featured': pointPackage.featured }"
      >
        <div class="package-content">
          <!-- <h2>{{ pointPackage.name }}</h2> -->
          <p class="points">{{ pointPackage.points }} 积分（¥{{ pointPackage.price }}）</p>
          <!-- <p class="price">¥{{ pointPackage.price }}</p> -->
          <ul class="features">
            <!-- <li v-for="feature in pointPackage.features" :key="feature">
              <span class="feature-icon">✓</span> {{ feature }}
            </li> -->
            <p class="price">{{ pointPackage.features }}</p>
          </ul>
          <p class="total_points">共{{ pointPackage.total_points }} 积分</p>
        </div>
        <button @click="buyPackage(pointPackage)" class="buy-button">
          立即购买
        </button>
      </div>
    </div>
    <div id="paymentModal" class="modal" style="display:none;">
      <div class="modal-content">
        <span class="close-button" @click="closeModal">&times;</span>
        <p>请使用微信扫码支付，支付完成后刷新页面查看积分，遇到支付问题联系管理员微信: gnayji</p>
        <iframe id="paymentFrame" src="" style="width:100%; height:280px;"></iframe>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref } from 'vue';
import BackHomeButton from '@/components/BackHomeButton.vue';
import PaymentModal from '@/components/PaymentModal.vue';
import axios from 'axios';

export default defineComponent({
  name: 'BuyPoints',
  components: {
    BackHomeButton,
    PaymentModal
  },
  setup() {
    const packages = ref([]);
    const showPaymentModal = ref(false);
    const selectedPackage = ref(null);
    const isLoggedIn = ref(false);
    
    const fetchPackages = async () => {
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.get('/api/packages', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        packages.value = response.data;
      } catch (error) {
        console.error('获取积分包时发生错误:', error);
      }
    };

    function openModal(url) {
      const modal = document.getElementById('paymentModal');
      const iframe = document.getElementById('paymentFrame');
      iframe.src = url;
      modal.style.display = 'block';
    }

    const closeModal = () => {
      const modal = document.getElementById('paymentModal');
      const iframe = document.getElementById('paymentFrame');
      iframe.src = '';
      modal.style.display = 'none';
    };
    const buyPackage = async (pointPackage) => {
      selectedPackage.value = pointPackage;
      try {
        const token = localStorage.getItem('access_token');
        const response = await axios.post('/api/get_payment_code', {
          packageId: pointPackage.id,
          packagePrice: pointPackage.price
        }, {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        if (response.data.url) {
          openModal(response.data.url);
        } else {
          console.error('No payment URL returned');
        }
      } catch (error) {
        console.error('获取支付链接时发生错误:', error);
      }
    };
    const closePaymentModal = () => {
      showPaymentModal.value = false;
      selectedPackage.value = null; 
    };
    const checkAuth = () => {
      try {
        const token = localStorage.getItem('access_token');
        if (!token) {
          this.$router.push('/login');
        } else {
          isLoggedIn.value = true;
        }
      } catch (error) {
        console.error('检查登录状态时发生错误:', error);
        isLoggedIn.value = false;
      }
    };
    return {
      packages,
      fetchPackages,
      buyPackage,
      showPaymentModal,
      selectedPackage,
      closePaymentModal,
      checkAuth,
      isLoggedIn,
      closeModal
    };
  },
  mounted() {
    this.checkAuth();
    this.fetchPackages();
  }
});
</script>
<style scoped>
.buy-points {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  color: #e0e0e0;
  background-color: #121212;
}
.title {
  text-align: center;
  color: #4CAF50;
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
.subtitle {
  text-align: center;
  color: #b0b0b0;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.points-packages {
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 1fr));
  row-gap: 4rem;     /* 控制行间距 */
  column-gap: 2rem;  /* 控制列间距 */
  margin-bottom: 3rem;
}
.package-card {
  background-color: #1e1e1e;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  height: 100%;
}
.package-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}
.package-card.featured {
  border: 2px solid #4CAF50;
  position: relative;
}
.package-card.featured::before {
  content: '推荐';
  position: absolute;
  top: -10px;
  right: 10px;
  background-color: #4CAF50;
  color: white;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 0.7rem;
}
.package-content {
  flex-grow: 1;
}
.package-card h2 {
  color: #4CAF50;
  margin-bottom: 0.75rem;
  font-size: 1.3rem;
}
.points {
  font-size: 1.3rem;
  color: #e0e0e0;
  margin-bottom: 0.75rem;
}
.total_points {
  font-size: 1.6rem;
  font-weight: bold;
  color: #FFD700;
  margin-bottom: 0.5rem;
}
.price {
  font-size: 1.3rem;
  color: #e0e0e0;
  margin-bottom: 0.75rem;
}
.features {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1.25rem;
  text-align: left;
}
.features li {
  margin-bottom: 0.4rem;
  color: #b0b0b0;
  font-size: 0.95rem;
}
.feature-icon {
  color: #4CAF50;
  margin-right: 0.3rem;
}
.buy-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 0.6rem 1rem;
  font-size: 0.9rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
}
.buy-button:hover {
  background-color: #45a049;
}
@media (max-width: 1200px) {
  .points-packages {
    grid-template-columns: repeat(2, minmax(260px, 1fr));
  }
}
@media (max-width: 768px) {
  .points-packages {
    grid-template-columns: minmax(260px, 1fr);
  }
}

.modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: none;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: #1e1e1e;
  margin: auto;
  padding: 0;
  width: 280px;
  height: 360px;
  border: 1px solid #888;
  color: #e0e0e0;
  margin-top: 200px;
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: white;
  text-decoration: none;
  cursor: pointer;
}
</style>