<template>
    <div class="tutorial-container">
      <div class="header">
        <BackHomeButton />
        <UserInfo />
      </div>
      
      <div class="tutorial-content">
        <!-- 项目介绍部分 -->
        <!-- <section class="tutorial-section project-intro">
          <div class="intro-content">
            <h2>墨影AIGC简介</h2>
            <div class="section-description">
              <p class="intro-paragraph">
                在AI颜值版块，断断续续两年时间，从一个小白到处付费学习，到现在开发了我们自己的云平台做共享模型。在整个付费学习的过程中，这个版块最大的问题就是重复付费，互联网 都喜欢跟风，每段时间都会有一个素材会火起来，会有新的模型推出来，新的技术出来，需要不停的付费学习。
              </p>
              <p class="intro-paragraph">
                所以墨影的雏形出来了。我们做共享，不断的更新模型，更新新的技术。开发的路程是坎坷的，在这里也感谢前期支持的学员，让我们在不断的测试完善。截止到1月20号，我们的模型已经更新到了36个，13个工作流；涵盖了各种风格类型，工作流配合出图；我们一直在更新，而且在涨价的过程中，最早的学员依然在享受更新！
              </p>
              <p class="intro-paragraph highlight">
                随着学员的日益增加，我们需要新的小伙伴加入，如果你有成熟的后端项目，如果你也是在颜值赛道深耕，如果你一个人在单打独斗，欢迎加入墨影团队！！！
              </p>
            </div>
          </div>
        </section> -->
        <section class="tutorial-section project-intro">
          <div class="intro-content">
            <h2>学员作品展示</h2>
            <div class="card-list">
              <div class="card" v-for="(image, index) in images" :key="index">
                <img :src="image.src" :alt="image.alt" class="card-image thumbnail" />
                <!-- <p class="card-description">{{ image.description }}</p> -->
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </template>
  
  <script>
  import BackHomeButton from '@/components/BackHomeButton.vue';
  import UserInfo from '@/components/UserInfo.vue';
  
  export default {
    name: 'Tutorial',
    components: {
      BackHomeButton,
      UserInfo
    },
    data() {
      return {
        steps: [
          
        ],
        images: [
          { src: '/stuimgs/mm1.webp', alt: '作品1', description: '作品描述1' },
          { src: '/stuimgs/mm2.webp', alt: '作品2', description: '作品描述2' },
          { src: '/stuimgs/mm3.webp', alt: '作品3', description: '作品描述3' },
          { src: '/stuimgs/mm4.webp', alt: '作品4', description: '作品描述4' },
          { src: '/stuimgs/mm5.webp', alt: '作品5', description: '作品描述5' },
          { src: '/stuimgs/mm6.webp', alt: '作品6', description: '作品描述6' },
          { src: '/stuimgs/mm7.webp', alt: '作品7', description: '作品描述7' },
          { src: '/stuimgs/mm8.webp', alt: '作品8', description: '作品描述8' },
          { src: '/stuimgs/mm9.webp', alt: '作品9', description: '作品描述9' },
          { src: '/stuimgs/mm10.webp', alt: '作品10', description: '作品描述10' },
          { src: '/stuimgs/mm11.webp', alt: '作品11', description: '作品描述11' },
          { src: '/stuimgs/mm12.webp', alt: '作品12', description: '作品描述12' },
          { src: '/stuimgs/mm13.webp', alt: '作品13', description: '作品描述13' },
          { src: '/stuimgs/mm14.webp', alt: '作品14', description: '作品描述14' },
          { src: '/stuimgs/mm15.webp', alt: '作品15', description: '作品描述15' },
          { src: '/stuimgs/mm16.webp', alt: '作品16', description: '作品描述16' },
          { src: '/stuimgs/mm17.webp', alt: '作品17', description: '作品描述17' },
          { src: '/stuimgs/mm18.webp', alt: '作品18', description: '作品描述18' },
          { src: '/stuimgs/mm19.webp', alt: '作品19', description: '作品描述19' },
          { src: '/stuimgs/mm20.webp', alt: '作品20', description: '作品描述20' },
        ],
      };
    },
    methods: {
      toggleFAQ(index) {
        this.faqs[index].isOpen = !this.faqs[index].isOpen;
      },
    }
  };
  </script>
  
  <style scoped>
  .tutorial-container {
    background: linear-gradient(135deg, #121212 0%, #1E1E1E 100%);
    color: #e0e0e0;
    padding: 40px;
    min-height: 100vh;
    font-family: 'Segoe UI', 'PingFang SC', 'Microsoft YaHei', sans-serif;
    line-height: 1.8;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    padding-bottom: 20px;
    border-bottom: 1px solid #333;
  }
  
  .tutorial-content {
    max-width: 99%;
    margin: 0 auto;
  }
  
  .tutorial-section {
    background-color: rgba(30, 30, 30, 0.8);
    border-radius: 15px;
    padding: 40px;
    margin-bottom: 40px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    transition: transform 0.3s ease;
  }
  
  .tutorial-section h2 {
    color: #4CAF50;
    border-bottom: 3px solid #4CAF50;
    padding-bottom: 15px;
    margin-bottom: 30px;
    text-align: center;
    font-size: 2.2rem;
  }
  
  .project-intro {
    text-align: left;
  }
  
  .section-description {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .intro-paragraph {
    font-size: 1.1rem;
    color: #e0e0e0;
    text-align: justify;
    margin-bottom: 15px;
    line-height: 2;
  }
  
  .intro-paragraph.highlight {
    color: #4CAF50;
    font-weight: bold;
    border-left: 4px solid #4CAF50;
    padding-left: 15px;
  }
  
  @media (max-width: 768px) {
    .tutorial-container {
      padding: 20px;
    }
  
    .intro-paragraph {
      font-size: 1rem;
    }
  }
  
  .card-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .card {
    flex: 0 1 calc(25% - 10px);
    margin-bottom: 20px;
  }
  
  .thumbnail {
    width: 100%;
    height: auto;
  }
  </style>