import { render, staticRenderFns } from "./ComfyUI_v2.vue?vue&type=template&id=16d448b9&scoped=true"
import script from "./ComfyUI_v2.vue?vue&type=script&lang=js"
export * from "./ComfyUI_v2.vue?vue&type=script&lang=js"
import style0 from "./ComfyUI_v2.vue?vue&type=style&index=0&id=16d448b9&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16d448b9",
  null
  
)

export default component.exports